// @flow strict
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';
import styled from 'styled-components';

const Position = styled.div`
  font-size: 16px;
  background-color: #C7E9BA;
  padding: 6px 10px;
  border-radius: 4px;
  margin: -15px 0px 5px 0px;
  width: 300px;
`;

const Author = () => {
  const { author } = useSiteMetadata();
  return (
    <div className={styles['author']}>
      <p className={styles['author__bio']}>
        {author.bio}
        <a
          className={styles['author__bio-twitter']}
          href={getContactHref('twitter', author.contacts.twitter)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>{author.name}</strong> on Twitter
        </a>
      </p>
      <Position>{author.position}</Position>
    </div>
  );
};

export default Author;
